/* eslint-disable */
import React, { Fragment, useState } from 'react';
import { Helmet } from "react-helmet";
import Layout from '../components/layout/Layout';

import './pages-css/contact.css'


const contact = () => {
    return (
        <Layout>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Learning Curve | Contact</title>
            </Helmet>
            <Fragment>
                <div className="bg-splash-contact">
                    <div className="container pb-5">
                        <div className="row">
                            <div className="col-lg pt-5 fade-right">
                                <h5 className="text-secondary py-2">GET IN TOUCH WITH US</h5>
                                <h1 className="text-dark py-3">Contact our tutors to learn more about our services:</h1>
                                <p style={{fontSize: '1.5rem'}} className="text-secondary py-2">We'd love to hear from you! Please reach out if you have any questions about the services we offer and how we can help.</p>
                                <div className="card shadow p-4">
                                    <h3 className="text-dark">Ways to contact us:</h3>
                                    <h5 className="text-secondary py-3">Gurleen Dhillon</h5>
                                    <span>
                                        <i style={{paddingRight: '1rem', fontSize: '1.8em'}} className="fas fa-phone-square-alt text-info"></i>
                                        <span style={{fontSize: '1.4em'}}><a style={{textDecoration: 'none'}} href="tel:647-622-6878" className="text-dark">(647) 622 6878</a></span>
                                    </span>
                                    <span>
                                        <i style={{paddingRight: '1rem', fontSize: '1.8em'}} className="fas fa-envelope-square text-info"></i>
                                        <span>gurleendhillon222@gmail.com</span>
                                    </span>
                                </div>
                            </div>
                            <div className="col-lg pt-5">
                                <div className="card shadow fade-up">
                                    <div className="p-3">
                                        <h3 className="text-dark py-3">Send a message and we'll get back to you:</h3>
                                        <form name="contact" method="post" action="/success" data-netlify="true" data-netlify-honeypot="bot-field">
                                            <input type="hidden" name="form-name" value="contact" />
                                            <div className="form-group">
                                                <label htmlFor="First">First Name</label>
                                                <input type="text" className="form-control" id="First-Name" name="First-Name" />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="Last-Name">Last Name</label>
                                                <input type="text" className="form-control" id="Last-Name" name="Last-Name"/>
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="Email">Email address</label>
                                                <input type="email" className="form-control" id="Email" name="Email"/>
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="Subject">Subject (optional) :</label>

                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="English" id="English" name="Subject[]"/>
                                                    <label htmlFor="Subject" className="form-check-label">
                                                        English
                                                    </label>
                                                </div>

                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="French" id="French" name="Subject[]"/>
                                                    <label htmlFor="Subject" className="form-check-label">
                                                        French
                                                    </label>
                                                </div>

                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="Math" id="Math" name="Subject[]"/>
                                                    <label htmlFor="Subject" className="form-check-label">
                                                        Math
                                                    </label>
                                                </div>

                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="Physics" id="Physics" name="Subject[]"/>
                                                    <label htmlFor="Subject" className="form-check-label">
                                                        Physics
                                                    </label>
                                                </div>
                                            </div>

                                            
                                            
                                            <div className="form-group">
                                                <label htmlFor="Details">Details:</label>
                                                <textarea className="form-control" id="Details" name="Details" rows="5"></textarea>
                                            </div>
                                            
                                            <button type="submit" className="btn btn-lg btn-block btn-info">Submit</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </Fragment>
        </Layout>
    )
}

export default contact
